






































import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    PoolCover: () => import('@/components/images/pool-cover.vue'),
  },
})
export default class HoverImage extends Vue {
  @Prop({
    required: true,
    default: () => {
      return {}
    },
  })
  keyInfo!: MarketKeyInfo
  @Prop({
    required: true,
    default: () => {
      return {}
    },
  })
  poolStore
  @Prop({ default: false }) showId!: boolean
  @Prop({ default: false }) showChain!: boolean

  get poolUrl() {
    return this.poolStore ? this.poolStore.coverUrl : ''
  }
}
